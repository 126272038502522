<template>
  <div>
    <a-breadcrumb :style="{ margin: '16px 0', padding: '0 50px' }">
      <a-breadcrumb-item>
        <span>
          个人资料
        </span>
      </a-breadcrumb-item>
    </a-breadcrumb>
    <a-layout-content class="content-wrapper">
      <user-info />
    </a-layout-content>

  </div>
</template>

<script>
import UserInfo from '@/views/users/personal_info/UserInfo.vue'
export default {
  name: 'RegisteredAgentUserInfo',
  components: {
    UserInfo
  }
}
</script>
<style lang="less" scoped>
.content-wrapper {
  padding: 0 50px 30px 50px;
  overflow: auto;
  height: calc(100vh - 146px);
}
</style>
